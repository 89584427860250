<template>
  <div class="step1">
    <div class="title">
      {{
        this.$i18n.locale == `ar` ?
          `الرجاء قراء العقد والشروط والاحكام الخاصة بالباقة للموافقه عليها واستكمال الطلب`
          :
          `Please read the contract and terms and conditions of the package to agree to it and complete the application`
      }}
    </div>
    <div class="contain">
      <h2>
        {{
          this.$i18n.locale == `ar` ?
            `عقد تقديم خدمات باقة رواد الأعمال بلس`
            :
            `Please reaContract to provide Entrepreneur Plus Package services`
        }}
      </h2>
      <p>
        {{
          this.$i18n.locale == `ar` ?
            `مسمى الخدمة : عقد تنفيذ خدمات`
            :
            `Name of the service: contract for the implementation of services`
        }}
      </p>
      <p>
        {{
          this.$i18n.locale == `ar` ?
            `بعون الله في هذا اليوم: 29 فبراير 2024، الموافق الخميس، ١٩ شعبان ١٤٤٥ هـ تم إبرام هذا العقد بين كلًا من:`
            :
            `With God’s help, on this day: February 29, 2024, corresponding to Thursday, Shaaban 19, 1445 AH, this contract
        was concluded between:`
        }}
      </p>
      <strong>
        {{
          this.$i18n.locale == `ar` ?
            `الطرف الأول:`
            :
            `the first side:`
        }}
      </strong>
      <p>{{
          this.$i18n.locale == `ar` ?
            `سيتم تحديده لاحقاً`
            :
            `To be determined later`
        }}</p>
      <br>
      <strong>
        {{
          this.$i18n.locale == `ar` ?
            `الطرف الثاني:`
            :
            `Second Party:`
        }}
      </strong>
      <p>
        {{
          this.$i18n.locale == `ar` ?
            `الطرف الثاني السادة/ Brenden Rocha ويمثله في توقيع العقد السيد/ة Brenden Rocha، رقم السجل التجاري لا يوجد،
        الرقم
        الضريبي لا يوجد. رقم الهوية 1231231232. العنوان الوطني tes رقم الجوال +966594701142 والبريد الالكتروني
        basharitunes@gmail.com. ويشار له بالعقد بالطرف الثاني.`
            :
            `The second party, Messrs. Brenden Rocha, is represented in signing the contract by Ms. Brenden Rocha.
        Commercial
        registration number does not exist. Tax number does not exist. ID number: 1231231232. National address: Mobile
        number +966594701142 and email: basharitunes@gmail.com. He is referred to in the contract as the second party.`
        }}
      </p>
      <br>
      <strong> {{ this.$i18n.locale == `ar` ? `التمهيد:` : `Preface:` }}</strong>
      <p> {{ this.$i18n.locale == `ar` ? `نظرا لما لدى الطرف الأول من الخبرة والمعرفة في مجال الخدمات المذكورة في البند
        الثالث ، ورغبة الطرف الثاني بالانتفاع من خدمات الطرف الأول بما لديه من الخبرات العلمية والعملية في تقديم الخدمات
        المتعلقة وذلك حسب المعايير والأنظمة المعمول بها في المملكة العربية السعودية فقد أتفق الطرفان وهما بكامل أهليتهما
        المعتبرة شرعا ونظاما للتعاقد على البنود التالية :` : `In view of the experience and knowledge that the first
        party
        has in the field of services mentioned in the third clause, and the desire of the second party to benefit from
        the
        services of the first party, with its scientific and practical experience in providing related services, in
        accordance with the standards and regulations in force in the Kingdom of Saudi Arabia, the two parties have
        agreed, in full capacity Legally and systematically considered for contracting the following items:` }}</p>
      <br>
      <strong>{{ this.$i18n.locale == `ar` ? `البند الأول:` : `First item:` }}</strong>
      <p> {{ this.$i18n.locale == `ar` ? `يعتبر التمهيد السابق والملاحق بهذا العقد جزءا لا يتجزأ منه كما لها نفس الحجية
        والاعتبارات وتشمل هذه الملاحق من أي نوع يتم التوقيع عليها من قبل طرفي هذا العقد.` : `The previous preamble and
        the
        annexes to this contract are considered an integral part of it and have the same validity and considerations.
        These include any annexes of any kind signed by both parties to this contract.` }}</p>
      <br>
      <strong>{{ this.$i18n.locale == `ar` ? `البند الثاني (محل العقد):` : `The second clause (the subject of the
        contract):` }}</strong>
      <p>{{ this.$i18n.locale == `ar` ? `قيام الطرف الأول بتقديم خدمة ( باقة رواد الأعمال بلس ) للطرف الثاني وتقديمها عن
        طريق الموقع الإلكتروني لمنصة إقرار ( www.egrar.sa ) والمرتبط بتقديم الخدمة المذكورة بالبند ثالثا.` : `The first
        party provides the service (Entrepreneurs Plus Package) to the second party and provides it through the website
        of
        the Igrar platform (www.egrar.sa), which is linked to providing the service mentioned in Clause Three.` }}</p>
      <br>
      <strong>{{ this.$i18n.locale == `ar` ? `البند الثالث وصف الخدمة:` : `The third item is description of the
        service:`
        }}</strong>
      <p> {{ this.$i18n.locale == `ar` ? `الباقة: باقة رواد الأعمال بلس وصف الخدمة: تقديم خدماتالتسويات البنكية,تسجيل
        القيود اليومية,إقفال الحسابات,التقارير الشهرية,اعداد ومراجعة الإقرارات الضريبية - ربع سنوي.` : `Package:
        Entrepreneurs Plus Package Service Description: Providing bank reconciliation services, recording daily entries,
        closing accounts, monthly reports, preparing and reviewing tax returns - quarterly.` }}</p>
      <br>
      <!-- <strong>{{ this.$i18n.locale == `ar` ? `البند الرابع (مدة العقد):` : `Clause Four (Contract Duration):`
        }}</strong> -->
      <!-- <p> {{ this.$i18n.locale == `ar` ? `عدد ( 3 ) شهر / اشهر ميلادية تبدا من تاريخ العقد ويتجدد العقد تلقائيا ما لم
        يخطر
        أحد الأطراف الآخر قبل انتهاء مدة العقد بـ 30 يوماً.` : `The number of (3) calendar months starts from the date
        of
        the contract, and the contract is automatically renewed unless one of the parties notifies the other 30 days
        before the end of the contract period.` }}</p> -->
    </div>
    <label class="agree" for="terms" style="cursor: pointer;">
      <input type="checkbox" id="terms" v-model="agree" @keydown="handleKeydown"  @change="HandelAgree">
      <span>
        {{ this.$i18n.locale == 'ar' ? 'الرجاء الموافقة على الشروط و الاحكام للاسئناف' :'Please agree to the terms and conditions to appeal' }}
      </span>
    </label>
  </div>
</template>
<script>
export default {
  data() {
    return {
      agree:false
    }
  },
methods:{
  handleKeydown(event) {
          if (event.key === 'Tab') {
            event.preventDefault(); // Prevent the default tab action
          }
        },
  HandelAgree(){
    this.$store.state.packageOrder.step1 = this.agree;
  }
}
};
</script>